import React, { Fragment, useRef, useState } from 'react'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { BsFillCloudArrowDownFill } from 'react-icons/bs'

import * as XLSX from 'xlsx'
import { Card, CardBody, Row, Col, FormGroup, Label, Input, Button } from 'reactstrap'
import { toast, Bounce, i18n } from '../../../Components'
import { MateriaisGet, ParticipanteGetById, ParticipantesGet, TpOrdemGet } from '../../../Service/ApiService'
import { Link } from 'react-router-dom'
import { getUser } from '../../../Reducers/storageApp'

export default function ElementImport({ onChange }) {
  const { contaId } = getUser()
  library.add(fab, faSpinner)
  const [statusOp, setStatusOp] = useState(0)
  const [file, setFile] = useState()
  const fileInput = useRef(null)

  async function onClear() {
    setFile(undefined)
    setStatusOp(0)
  }
  async function loadParEndereco(participanteId) {
    var result = await ParticipanteGetById(participanteId)
    return `${result.endLogradouro} ${result.endNumero} - ${result.endBairro} - ${result.endCidade} - ${result.endEstado}`
  }

  async function DataImport(csv) {
    const initialData = { ordemId: 0, tpOrdem: '', participante: '', parEndereco: '', itens: [], observacao: '', pedCompra: '' }
    const lines = csv.split('\n')
    let lRow = true
    let nRow = 8
    let _importLog = []

    //Cabeçalho
    let dRow = lines[2].split(';')

    if (dRow[0] !== '1.0') {
      toast('A sua planilha está desatualizada, baixe o modelo, e preencha novamente', { type: 'error', transition: Bounce, closeButton: true, autoClose: 10000, position: 'top-right' })
      onClear()
      return
    }

    initialData.pedCompra = dRow[1]
    //TpOrdem
    if (!dRow[2]) {
      toast('O tipo do pedido é obrigatório, corrija os dados da planilha', { type: 'error', transition: Bounce, closeButton: true, autoClose: 10000, position: 'top-right' })
      onClear()
      return
    }
    const respTpOrdem = await TpOrdemGet({ ativo: true, nome: dRow[2] })
    if (respTpOrdem?.length !== 1) {
      toast('Tipo de ordem não encontrada, corrija os dados da planilha', { type: 'error', transition: Bounce, closeButton: true, autoClose: 10000, position: 'top-right' })
      onClear()
      return
    } else initialData.tpOrdem = { value: respTpOrdem[0].tpOrdemId, label: respTpOrdem[0].nome, obrigaLote: respTpOrdem[0].obrigaLote, nrDiasPrevEntrega: respTpOrdem[0].nrDiasPrevEntrega, diaSemana: respTpOrdem[0].diaSemana, diasAntecedencia: respTpOrdem[0].diasAntecedencia }

    //Participante
    const respParticipante = await ParticipantesGet({ ativo: true, contaId, CpfCnpj: dRow[3].replace(/\D/g, '') })
    if (respParticipante?.length !== 1) _importLog.push({ message: 'Cliente não encontrado, selecione manualmente' })
    else {
      const part = await loadParEndereco(respParticipante[0].participanteId)
      initialData.participante = { value: respParticipante[0].participanteId, label: respParticipante[0].nomeFantasia }
      initialData.parEndereco = part
    }

    dRow = lines[5].split(';')
    initialData.observacao = dRow[1]

    let itens = []
    while (lRow) {
      dRow = lines[nRow].split(';')
      if (dRow[1]) {
        var respMaterial = await MateriaisGet({ ativo: true, tpOrdemId: initialData.tpOrdem.value, materialIdExt: dRow[1], obrigaLote: initialData.tpOrdem.obrigaLote })
        if (respMaterial?.length > 0) {
          itens.push({
            materialId: respMaterial[0].materialId,
            materialIdExt: respMaterial[0].materialIdExt,
            nmMaterial: respMaterial[0].nome,
            qtde: dRow[3],
            uniMedida: respMaterial[0].uniMedida,
            material: { value: respMaterial[0].materialId, label: respMaterial[0].nome },
          })
        } else _importLog.push({ message: 'Material não encontrado - Código: ' + dRow[1] })
      }
      nRow += 1
      if (lines.length <= nRow) lRow = false
    }
    initialData.itens = itens

    onChange({ ...initialData, log: _importLog })
  }

  async function onFileSelect(e) {
    e.stopPropagation()
    e.preventDefault()

    if (e.target.files.length > 0) {
      setStatusOp(1)
      setFile(e.target.files[0])

      const f = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (evt) => {
        const bstr = evt.target.result
        const wb = XLSX.read(bstr, { type: 'binary' })
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]

        const data = XLSX.utils.sheet_to_csv(ws, {
          FS: ';',
          dateNF: 'yyyy-MM-dd',
        })

        DataImport(data)
      }
      reader.readAsBinaryString(f)
    }
    e.target.value = null
  }

  return (
    <Fragment>
      {
        {
          0: (
            <Card className="main-card mb-2">
              <CardBody>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="name">Selecione o arquivo *</Label>

                      {file !== undefined ? <Input type="text" name="nmFile" id="nmFile" value={file.name} disabled /> : <Input ref={fileInput} type="file" name="file" id="file" onChange={onFileSelect} accept=".xls, .xlsx" />}
                    </FormGroup>
                  </Col>

                  <Col md={6} className="text-right" style={{ margin: 'auto 0' }}>
                    <Link style={{ textDecoration: 'none' }} to={`${process.env.REACT_APP_API}/SysArquivo/GetModExcelOrderImp`} target="_blank" className="btn-wide btn mr-2 btn-outline-primary btn-square">
                      <BsFillCloudArrowDownFill size={20} /> Download Excel modelo
                    </Link>

                    <Button type="button" onClick={() => onChange()} className="btn-wide btn-lg" color="secondary">
                      {i18n.t('layout.btnGoBack')}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ),
          1: (
            <Card className="main-card mb-2">
              <CardBody style={{ padding: '40px 0' }}>
                <Row>
                  <Col md="12" className="text-center">
                    <FontAwesomeIcon icon={['fas', 'spinner']} pulse fixedWidth size="5x" />
                  </Col>
                </Row>
                <Row style={{ padding: '20px 0 0 0' }}>
                  <Col md="12" className="text-center">
                    Processando o pedido, aguarde ...
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ),
          default: <Fragment />,
        }[statusOp]
      }
    </Fragment>
  )
}
