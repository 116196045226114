import React, { Fragment, useEffect, useState } from 'react'
import { Card, CardBody, Row, Col, FormGroup, Label, Input, Button, CustomInput, CardTitle } from 'reactstrap'

import { SysParametroAddOrUpdate, SysParametroGet } from '../../Service/ApiService'
import { PageTitle, ReactCSSTransitionGroup, useNavigate, toast, Bounce, i18n, InputNumber, Tooltip, Loading } from '../../Components'
import CreatableSelect from 'react-select/creatable'

const initValues = { urlBasePainel: '', diasAvisoFechaLote: '', urlEnvioOrdemSAP: '', eMailsLogErros: '', stPortal: 'D', conFacil_Url: '', conFacil_Login: '', conFacil_Senha: '' }

export default function FormCadastro() {
  const navigate = useNavigate()
  const [values, setValues] = useState()
  const [isLoading, setIsLoading] = useState(false)

  async function loadValues() {
    try {
      const response = await SysParametroGet()

      if (response) {
        let _values = { ...response }
        _values.eMailsLogErros = []
        response.eMailsLogErros?.split(';').forEach((d) => {
          _values.eMailsLogErros.push({ value: d, label: d })
        })

        _values.conFacil_Url = response.conFacil.urlBase
        _values.conFacil_Login = response.conFacil.login
        _values.conFacil_Senha = response.conFacil.senha

        setValues({ ...initValues, ..._values })
      } else setValues(initValues)
    } catch {}

    setIsLoading(false)
  }

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }
  function onChangeCat(newValue, actionMeta) {
    const { name } = actionMeta
    setValues({ ...values, [name]: newValue })
  }

  async function onSubmit(ev) {
    ev.preventDefault()
    if (!values.urlBasePainel || !values.diasAvisoFechaLote || !values.urlEnvioOrdemSAP || !values.userEnvioOrdemSAP || !values.passEnvioOrdemSAP) {
      toast(i18n.t('tpOrder.add.MSG_ErrorValidate'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }

    setIsLoading(true)
    try {
      let _values = { ...values }
      _values.eMailsLogErros = ''
      values.eMailsLogErros.forEach((d) => {
        _values.eMailsLogErros += (_values.eMailsLogErros != '' ? ';' : '') + d.value
      })

      _values.conFacil = { urlBase: values.conFacil_Url, login: values.conFacil_Login, senha: values.conFacil_Senha }

      const response = await SysParametroAddOrUpdate(_values)
      if (response.result) window.location.href = '/#/admin'
      else {
        toast(response.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })

        setIsLoading(false)
      }
    } catch {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    loadValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Loading isVisible={isLoading} />
      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <div className="body-tabs body-tabs-layout">
          <PageTitle heading="Parâmetros" subheading="Parâmetros e configurações do portal" />
          <div className="page-content">
            <Card className="main-card mb-2">
              {values && (
                <CardBody>
                  <Row>
                    <Col md={8}>
                      <FormGroup>
                        <Label>
                          URL base do portal *
                          <Tooltip placement="auto" text="?" content="Essa url é utilizada na composição dos link enviados por e-mail" key="urlBasePainel" id="urlBasePainel" />
                        </Label>
                        <Input type="text" onChange={onChange} name="urlBasePainel" id="urlBasePainel" value={values.urlBasePainel} />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label>
                          Dias fechamento de lote *
                          <Tooltip className="tooltip" placement="auto" text="?" content="Quantidade de dias que os lotes em vencimento devem ser listados na pagina inicial" key="diasAvisoFechaLote" id="diasAvisoFechaLote" />
                        </Label>
                        <InputNumber onChange={onChange} name="diasAvisoFechaLote" id="diasAvisoFechaLote" value={values.diasAvisoFechaLote} />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <Label>Status portal</Label>
                      <CustomInput type="select" onChange={onChange} id="stPortal" name="stPortal" value={values.stPortal}>
                        <option value=""></option>
                        <option value="D">Disponível</option>
                        <option value="M">Em manutenção</option>
                      </CustomInput>
                    </Col>

                    <Col md={12}>
                      <FormGroup>
                        <Label>Endereços de e-mail para envio de logs de erros (quando for mais de um, separar por ;)</Label>

                        <CreatableSelect placeholder="Digite para adicionar" noOptionsMessage={() => 'Digite para adicionar'} name="eMailsLogErros" className="basic-multi-select" classNamePrefix="select" onChange={onChangeCat} value={values.eMailsLogErros} isClearable isMulti />
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <CardTitle>Integração SAP</CardTitle>
                    </Col>
                    <Col md={8}>
                      <FormGroup>
                        <Label>
                          Url base *
                          <Tooltip className="tooltip" placement="auto" text="?" content="Url do endpoint da api SAP para envio das ordens" key="urlEnvioOrdemSAP" id="urlEnvioOrdemSAP" />
                        </Label>
                        <Input type="text" onChange={onChange} name="urlEnvioOrdemSAP" id="urlEnvioOrdemSAP" value={values.urlEnvioOrdemSAP} />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label>Usuário * </Label>
                        <Input type="text" onChange={onChange} name="userEnvioOrdemSAP" id="userEnvioOrdemSAP" value={values.userEnvioOrdemSAP} />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label>Senha *</Label>
                        <Input type="text" onChange={onChange} name="passEnvioOrdemSAP" id="passEnvioOrdemSAP" value={values.passEnvioOrdemSAP} />
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <CardTitle>Integração Confirma Fácil</CardTitle>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          Url base *
                          <Tooltip className="tooltip" placement="auto" text="?" content="Url do endpoint da api confirma fácil" key="conFacil_Url" id="conFacil_Url" />
                        </Label>
                        <Input type="text" onChange={onChange} name="conFacil_Url" id="conFacil_Url" value={values.conFacil_Url} />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label>Usuário </Label>
                        <Input type="text" onChange={onChange} name="conFacil_Login" id="conFacil_Login" value={values.conFacil_Login} />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label>Senha</Label>
                        <Input type="text" onChange={onChange} name="conFacil_Senha" id="conFacil_Senha" value={values.conFacil_Senha} />
                      </FormGroup>
                    </Col>

                    <Col md={12} className="d-block text-right">
                      <Button type="button" onClick={() => navigate(-1)} className="btn-wide mr-2 btn-lg" color="secondary">
                        {i18n.t('layout.btnGoBack')}
                      </Button>
                      <Button type="button" onClick={onSubmit} className="btn-wide btn-lg" color="success">
                        {i18n.t('tpOrder.add.form.buttonSave')}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              )}
            </Card>
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </>
  )
}
