import React, { useEffect, useState } from 'react'
import { i18n, moment } from '../../../Components'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { Card, CardBody, CardTitle, Col, Row, Table } from 'reactstrap'

import Timeline from './Timeline'
import { formatChaveNfe } from '../../../helpers/functions'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 2, border: '1px solid #e9ecef', background: 'white', padding: '10px 4px' }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

export default function ScrollableTabsButtonAuto({ records, value }) {
  const [steps, setSteps] = useState()
  const [values, setValues] = useState(0)

  const handleChange = (event, newValue) => {
    setValues(newValue)
  }

  useEffect(() => {
    let steps = []
    if (value.situacao > 10) steps.push({ label: 'Ordem enviada', date: value.dhAtualizacao ? value.dhAtualizacao : value.dhCadastro })
    else steps.push({ label: 'Ordem enviada', date: '' })

    steps.push({ label: 'Ordem Confirmada', date: value.dhCadastroExt ? value.dhCadastroExt : '' })

    steps.push({ label: 'Remessa', date: '' })
    steps.push({ label: 'Emissão NF', date: '' })
    steps.push({ label: 'Embarque', date: '' })
    steps.push({ label: 'Transporte iniciado', date: '' })
    steps.push({ label: 'Entrega realizada', date: '' })

    setSteps(steps)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [records])

  function getStepsItem(item) {
    let steps = []

    if (value.situacao > 10) steps.push({ label: 'Ordem enviada', date: value.dhAtualizacao ? value.dhAtualizacao : value.dhCadastro })
    else steps.push({ label: 'Ordem enviada', date: '' })

    steps.push({ label: 'Ordem Confirmada', date: value.dhCadastroExt ? value.dhCadastroExt : '' })

    steps.push({ label: 'Remessa', date: item.dhRemessa ? moment(item.dhRemessa).format('DD/MM/YYYY') : '' })
    steps.push({ label: 'Emissão NF', date: item.dhNotaFiscal ? moment(item.dhNotaFiscal).format('DD/MM/YYYY') : '' })

    steps.push({ label: 'Embarque', date: item.dhEmbarque ? moment(item.dhEmbarque).format('DD/MM/YYYY') : '' })

    if (item.dhIniTransporte) steps.push({ label: 'Transporte iniciado', date: moment(item.dhIniTransporte).format('DD/MM/YYYY HH:mm') })
    else steps.push({ label: 'Aguardando Transporte', date: '' })

    if (item.dhEntrega) steps.push({ label: 'Entrega realizada', date: moment(item.dhEntrega).format('DD/MM/YYYY HH:mm') })
    else if (!item.dtPrevEntrega) steps.push({ label: 'Aguardando previsão de entrega', date: '' })
    else steps.push({ label: `Entrega prevista <br /> ${moment(item.dtPrevEntrega).format('DD/MM/YYYY')}`, date: '' })

    return steps
  }

  return (
    <Col md={12}>
      <Card className="main-card mb-2">
        <CardBody>
          <CardTitle>Remessas</CardTitle>
          <Row>
            <Col md={12}>
              {records?.length === 0 ? (
                <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
                  <Timeline steps={steps} />
                </div>
              ) : (
                <Box sx={{ bgcolor: '#e9ecef' }}>
                  <Tabs value={values} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs">
                    {records?.map((item, index) => (
                      <Tab key={`Tabs-${item.ordRemessaId}`} label={item.ordRemessaIdExt} {...a11yProps({ index })} style={{ color: '#fff', background: `${item.dhEntrega ? '#3ac47d' : '#f7b924'}` }} />
                    ))}
                  </Tabs>
                  {records?.map((item, index) => (
                    <TabPanel key={`TabPanel-${item.ordRemessaId}`} value={values} index={index}>
                      <Row>
                        <Col md={12} className="mt-4 mb-4">
                          <Timeline steps={getStepsItem(item)} />
                        </Col>

                        <Col md={6}>
                          <Row>
                            <Col md={12}>
                              <strong>Remessa:</strong> {item.ordRemessaIdExt}
                            </Col>
                            <Col md={12}>
                              <strong>Transportadora:</strong> {item.transportadora}
                            </Col>
                            <Col md={12}>
                              <strong>Previsão de entrega:</strong> {item.dtPrevEntrega ? moment(item.dtPrevEntrega).format('DD/MM/YYYY') : ''}
                            </Col>
                            <Col md={12}>
                              <strong>Número NF:</strong> {item.nrNotaFiscal}
                              {item.serieNotaFiscal ? `-${item.serieNotaFiscal}` : ''}
                            </Col>
                            <Col md={12}>
                              <strong>Chave NFe:</strong> {formatChaveNfe(item.chaveNfe)}
                            </Col>
                            <Col md={12}>
                              <strong>Fatura:</strong> {item.nrFatura}
                            </Col>
                          </Row>
                        </Col>

                        <Col md={6} className="tb-report position-relative table-responsive">
                          <CardTitle style={{ marginBottom: '0.5rem' }}>
                            <span>Itens</span>
                          </CardTitle>
                          <Table striped className="mb-4">
                            <thead>
                              <tr>
                                <th>{i18n.t('order.itens.grid.materialId')} </th>
                                <th>{i18n.t('order.itens.grid.materialName')}</th>
                                <th style={{ textAlign: 'right' }}>Qtde</th>
                                <th style={{ textAlign: 'center' }}>Lote</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item.itens.map((p) => (
                                <tr key={`Rem-${p.ordRemItemId}`}>
                                  <td>{p.materialIdExt}</td>
                                  <td>{p.nmMaterial}</td>
                                  <td style={{ textAlign: 'right' }}>{p.qtde}</td>
                                  <td style={{ textAlign: 'center' }}>{p.loteExt}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </TabPanel>
                  ))}
                </Box>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  )
}
