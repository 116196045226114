import React from 'react'

import Stack from '@mui/material/Stack'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { TbFileInvoice } from 'react-icons/tb'

import { FiSend, FiTruck } from 'react-icons/fi'
import { BsBoxes, BsBoxSeam } from 'react-icons/bs'
import { FaRegCheckCircle } from 'react-icons/fa'
import { LiaPeopleCarrySolid } from 'react-icons/lia'

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient( 95deg, #dd9d08 0%, #f7b924 50%, #f9c753 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient( 136deg, #2f9d64 0%, #3ac47d 50%, #62d097 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 5,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}))

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: 'white',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage: 'linear-gradient( 136deg, #dd9d08 0%, #f7b924 50%, #f9c753 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage: 'linear-gradient( 136deg, #2f9d64 0%, #3ac47d 50%, #62d097 100%)',
  }),
}))

function ColorlibStepIcon(props) {
  const { active, completed, className } = props

  const icons = {
    1: <FiSend size={24} />,
    2: <FaRegCheckCircle size={24} />,
    3: <BsBoxSeam size={28} />,
    4: <TbFileInvoice size={28} />,
    5: <LiaPeopleCarrySolid size={28} />,
    6: <FiTruck size={28} />,
    7: <BsBoxes size={28} />,
  }

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  )
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
}

export default function Element({ steps = [] }) {
  function getStepId() {
    let stepId = 0
    steps.forEach((d) => {
      if (d.date !== '') stepId = stepId + 1
    })

    return stepId
  }
  function renderLabel(step) {
    var _label = step.label.split('<br />')
    return _label?.map((d, index) => {
      if (_label.length > index + 1)
        return (
          <span>
            {d}
            <br />
          </span>
        )
      return d
    })
  }

  return (
    <Stack sx={{ width: '100%' }} spacing={10}>
      {steps.length > 0 && (
        <Stepper alternativeLabel activeStep={getStepId()} connector={<ColorlibConnector />}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {renderLabel(step)} <br /> {step.date}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
    </Stack>
  )
}
