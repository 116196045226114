import React from 'react'
import File from '../../Document/Query/File'
import { Card, CardBody, CardTitle, Col, Row, Table } from 'reactstrap'
import { MsgSemRegistros } from '../../../Components'

export default function Element({ files }) {
  return (
    <Col md={6}>
      <Card className="main-card mb-2">
        <CardBody style={{ padding: '0.75rem' }}>
          <CardTitle>Arquivos</CardTitle>
          {files === undefined || files?.length === 0 ? (
            <MsgSemRegistros />
          ) : (
            <Row>
              <Col className="tb-report position-relative table-responsive">
                <Table striped className="mb-2 mt-2">
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th style={{ width: '100px', textAlign: 'right' }}>Tamanho</th>
                      <th style={{ width: '100px', textAlign: 'center' }}>Dh Upload</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {files.map((d) => (
                      <File key={d.sysArquivoId} values={d} cardSize={12} />
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    </Col>
  )
}
