import { Fragment, React } from 'react'
import { CardCopy } from './style'
import { i18n } from '../../translate/i18n'

const version = process.env.REACT_APP_VERSION

const Footer = () => {
  return (
    <div className="app-footer">
      <CardCopy className="app-footer__inner">
        <div className="app-footer-left">
          <span>
            <strong> &copy; {i18n.t('layout.footer.copyright')}</strong> | Version: {version}
          </span>
        </div>

        <div className="app-footer-right">
          <span>{i18n.t('layout.footer.developBy')}</span>
        </div>
      </CardCopy>
    </div>
  )
}

export default Footer
